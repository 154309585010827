import * as React from "react";
import { Jodit } from "jodit";

export const JoditBaseEditor = (props: {
    content: string;
    onChange?: (content: string) => void;
    config: object
}) => {
    const editor = React.useRef(null);
    const [local, setLocal] = React.useState<string>(props.content);
    const [jodit, setJodit] = React.useState<Jodit>(null);

    const handleLocalChange = (value: string) => {
        setLocal(value);
    };

    const handleLocalBeforePaste = (value: string) => {
        // disable sanitizing because it will remove team tag
        // should enable again once we refactored permissioned content role
        // DOMPurify.sanitize(value);
        return value;
    };

    React.useEffect(() => {
        if (!!editor.current && !jodit) {
            const element = editor.current;
            const jodit = Jodit.make(element, props.config);
            setJodit(jodit);
            jodit.events.on("change", handleLocalChange);
            jodit.events.on("beforePaste", handleLocalBeforePaste);
        }

        return () => {
            jodit && jodit.events.off("change", handleLocalChange);
            jodit && jodit.events.off("beforePaste", handleLocalBeforePaste);
            jodit && jodit.destruct();
        };
    }, [editor.current]);

    React.useEffect(() => {
        if (!!props.onChange) {
            props.onChange(local);
        }
    }, [local]);

    React.useEffect(() => {
        if (jodit && jodit.value !== props.content) {
            jodit.value = props.content;
        }
    }, [props.content]);

    return <textarea ref={editor} value={props.content}/>;
};