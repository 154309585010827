export const closest = (tags, el: Node, editor) => {
    const condition = (node) => new RegExp(`^(${tags})$`, "i").test(node.nodeName);
    let closest = el;
    do {
        if (condition(closest)) {
            return closest;
        }
        closest = closest.parentElement;
    } while (closest && closest !== editor.editor);
    return null;
};

export const nextSiblings = (el: Node) => {
    const nextSiblings = [];
    let current = el;
    while ((current as Element).nextElementSibling) {
        current = (current as Element).nextElementSibling;
        nextSiblings.push(current);
    }
    return nextSiblings;
};