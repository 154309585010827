export namespace Preference {
    export const KEY = "preference";

    export enum Lab {
        InPlaceContainerEditing = "In-place Container Editing",
        DevMode = "Development Mode",
        WhosMyLawyerFeature = "Whos My Lawyer Feature",
        AuthLogging = "AuthLogging"
    }
    export class Value {
        userId: string;
        instanceId: string;
        labs: Set<Lab>;
    }
}