import { AdminPreferences, Instance } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Checkbox,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    Select,
    SpaceBetween,
    Toggle
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ApiRunnerStatus, useAPI2 } from "../../hooks/api-hook";
import { InstanceModel } from "../../model/instance-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { UIField } from "../common/UIField";
import { Preference } from "../../setup/preference";

export namespace InstancePreferences {
    export const Main = (props: {
        state: UIModel.State<Instance>;
    }) => {
        const context = React.useContext(AppContext);
        const updateInstanceRunner = useAPI2(context.getInstanceAPI().update);
        const updateInstanceState = InstanceModel.UpdatePreferencesState.use({
            template: props.state.value
        });

        const onUpdate = () => {
            updateInstanceRunner.invoke(
                InstanceModel.UpdatePreferencesState.toInput(updateInstanceState)
            );
        };

        React.useEffect(() => {
            if (updateInstanceRunner.status === "Succeeded") {
                props.state.setValue(updateInstanceRunner.output);
            }
        }, [updateInstanceRunner.status]);

        const commonWidgetProps: InstancePrefWidgets.Props = {
            state: updateInstanceState,
            onUpdate: onUpdate,
            runnerStatus: updateInstanceRunner.status
        };

        return (
            <React.Fragment>
                <SpaceBetween size="m">
                    <InstancePrefWidgets.OrganizationPreferences {...commonWidgetProps} />
                    <InstancePrefWidgets.NotificationPreferences {...commonWidgetProps} />
                    <InstancePrefWidgets.SearchPreferences {...commonWidgetProps} />
                    <InstancePrefWidgets.RequestHomePageImageChange />
                    <InstancePrefWidgets.RequestPublicAccessSettingChange />
                </SpaceBetween>
            </React.Fragment>
        );
    };
}

export namespace InstancePrefWidgets {
    export interface Props {
        state: InstanceModel.UpdatePreferencesState;
        onUpdate: () => void;
        runnerStatus: ApiRunnerStatus;
    }

    export const SearchPreferences = (props: Props) => {
        const [editing, setEditing] = React.useState<boolean>(false);

        React.useEffect(() => {
            if (props.runnerStatus === "Succeeded") {
                setEditing(false);
            } else if (props.runnerStatus === "Error") {
                props.state.reset();
            }
        }, [props.runnerStatus]);

        return <Container header={<Header variant="h3" actions={
            !editing &&
            <Box float="right">
                <Button
                    onClick={() => {
                        props.state.reset();
                        setEditing(true);
                    }}
                    variant="primary">
                    Edit
                </Button>
            </Box>}>
                Search Preferences
            </Header>}>
                <Form
                    actions={editing && (
                        <div>
                            <Button onClick={() => {
                                props.state.reset();
                                setEditing(false);
                            }} variant="link">
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={props.onUpdate}
                                disabled={!props.state.isDirty()}
                                loading={props.runnerStatus === "Running"}>
                                Submit
                            </Button>
                        </div>
                )}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <ColumnLayout columns={1} borders="horizontal">
                            <ColumnLayout columns={1}>
                                <Header
                                    description="When enabled, users will not see search results that they do not have permission to view"
                                    variant="h3">
                                    Permission Controlled Search
                                </Header>
                                <Box margin={{"left" : "m"}}>
                                    <Toggle
                                        checked={props.state.showAuthorizedSearchResultsOnly.value}
                                        onChange={( event: { detail: { checked: boolean } } ) =>
                                            props.state.showAuthorizedSearchResultsOnly.setValue(event.detail.checked)
                                        }
                                        disabled={!editing}
                                    >Enable Permission Controlled Search</Toggle>
                                </Box>
                            </ColumnLayout>
                            <ColumnLayout columns={1}>
                                <Header variant="h3">
                                    Show Sort By
                                </Header>
                                    <Box margin={{"left" : "m"}}>
                                        <Toggle
                                            checked={props.state.sortByUpdated.value}
                                            onChange={( event: { detail: { checked: boolean } } ) =>
                                                props.state.sortByUpdated.setValue(event.detail.checked)
                                            }
                                            disabled={!editing}
                                        >Last Updated Date</Toggle>
                                    </Box>
                                <Box margin={{"left" : "m"}}>
                                    <Toggle
                                        checked={props.state.sortByCreated.value}
                                        onChange={( event: { detail: { checked: boolean } } ) =>
                                            props.state.sortByCreated.setValue(event.detail.checked)
                                        }
                                        disabled={!editing}
                                    >Created Date</Toggle>
                                </Box>
                            </ColumnLayout>
                            <ColumnLayout columns={1}>
                                <SpaceBetween size="xxl" direction="horizontal">
                                    <Header variant="h3">
                                        Results per page
                                    </Header>
                                    <Box margin={{"top": "xs"}}>
                                        <Toggle
                                            checked={props.state.paginationPrefs.value.length > 0}
                                            onChange={( event: { detail: { checked: boolean } }) => {
                                                if (event.detail.checked) {
                                                    props.state.paginationPrefs.setValue(["5", "10", "20", "25", "50"]);
                                                } else {
                                                    props.state.paginationPrefs.setValue([]);
                                                }
                                            }}
                                            disabled={!editing}
                                        />
                                    </Box>
                                </SpaceBetween>
                                <Box margin={{"left" : "m"}}>
                                    <SpaceBetween size="xl" direction="horizontal">
                                        <Checkbox
                                            checked={props.state.paginationPrefs.value.includes("5")}
                                            onChange={( event: { detail: { checked: boolean } } ) => props.state.updatePaginationPreference("5", event.detail.checked)}
                                            disabled={!editing}
                                        >5</Checkbox>
                                        <Checkbox
                                            checked={props.state.paginationPrefs.value.includes("10")}
                                            onChange={( event: { detail: { checked: boolean } } ) => props.state.updatePaginationPreference("10", event.detail.checked)}
                                            disabled={!editing}
                                        >10</Checkbox>
                                        <Checkbox
                                            checked={props.state.paginationPrefs.value.includes("20")}
                                            onChange={( event: { detail: { checked: boolean } } ) => props.state.updatePaginationPreference("20", event.detail.checked)}
                                            disabled={!editing}
                                        >20</Checkbox>
                                        <Checkbox
                                            checked={props.state.paginationPrefs.value.includes("25")}
                                            onChange={( event: { detail: { checked: boolean } } ) => props.state.updatePaginationPreference("25", event.detail.checked)}
                                            disabled={!editing}
                                        >25</Checkbox>
                                        <Checkbox
                                            checked={props.state.paginationPrefs.value.includes("50")}
                                            onChange={( event: { detail: { checked: boolean } } ) => props.state.updatePaginationPreference("50", event.detail.checked)}
                                            disabled={!editing}
                                        >50</Checkbox>
                                    </SpaceBetween>
                                </Box>
                            </ColumnLayout>
                        </ColumnLayout>
                        <ColumnLayout columns={1} borders="horizontal">
                            <Header variant="h3">
                                Show Filter By
                            </Header>
                            <Box margin={{"left" : "m"}}>
                                <Toggle
                                    checked={props.state.filterByAuthor.value}
                                    onChange={( event: { detail: { checked: boolean } } ) => props.state.filterByAuthor.setValue(event.detail.checked)}
                                    disabled={!editing}
                                >
                                    Author
                                </Toggle>
                            </Box>
                            <Box margin={{"left" : "m"}}>
                                <SpaceBetween size="m">
                                    <Toggle
                                        checked={props.state.filterByFileType.value.length > 0}
                                        onChange={( event: { detail: { checked: boolean } } ) => {
                                            if (event.detail.checked) {
                                                props.state.filterByFileType.setValue(["Page", "Document", "Link"]);
                                            } else {
                                                props.state.filterByFileType.setValue([]);
                                            }
                                        }}
                                        disabled={!editing}
                                    >
                                        File Type {props.state.filterByFileType.value.length === 0 && <span>
                                            <small>
                                                <em>
                                                    ( All file types are searchable but not filterable )
                                                </em>
                                            </small>
                                        </span>}
                                    </Toggle>
                                    <Box margin={{"left": "xxxl"}}>
                                        <SpaceBetween size="xs" direction="vertical">
                                            <Checkbox
                                                checked={props.state.filterByFileType.value.includes("Page")}
                                                onChange={( event: { detail: { checked: boolean }}) => props.state.updateFileTypePreference("Page", event.detail.checked)}
                                                disabled={!editing}
                                            >Page</Checkbox>
                                            <Checkbox
                                                disabled={!editing}
                                                checked={props.state.filterByFileType.value.includes("Document")}
                                                onChange={( event: { detail: { checked: boolean }}) => props.state.updateFileTypePreference("Document", event.detail.checked)}
                                            >Document</Checkbox>
                                            <Checkbox
                                                disabled={!editing}
                                                checked={props.state.filterByFileType.value.includes("Link")}
                                                onChange={( event: { detail: { checked: boolean }}) => props.state.updateFileTypePreference("Link", event.detail.checked)}
                                            >Link</Checkbox>
                                        </SpaceBetween>
                                    </Box>
                                </SpaceBetween>
                            </Box>
                            <Box margin={{"left" : "m"}}>
                                <Toggle
                                    checked={props.state.filterByPageLibrary.value}
                                    onChange={( event: { detail: { checked: boolean } } ) => props.state.filterByPageLibrary.setValue(event.detail.checked)}
                                    disabled={!editing}
                                >Page Library</Toggle>
                            </Box>
                            <Box margin={{"left" : "m"}}>
                                <Toggle
                                    checked={props.state.filterByLastUpdated.value}
                                    onChange={( event: { detail: { checked: boolean } } ) => props.state.filterByLastUpdated.setValue(event.detail.checked)}
                                    disabled={!editing}
                                >Last Updated Date</Toggle>
                            </Box>
                            <Box margin={{"left" : "m"}}>
                                <Toggle
                                    checked={props.state.filterByPermissionGroup.value}
                                    onChange={( event: { detail: { checked: boolean } } ) => props.state.filterByPermissionGroup.setValue(event.detail.checked)}
                                    disabled={!editing}
                                >Permission Group</Toggle>
                            </Box>
                        </ColumnLayout>
                    </ColumnLayout>
                </Form>
            </Container>;
    };

    export const NotificationPreferences = (props: Props) => {
        const context = React.useContext(AppContext);
        const [editing, setEditing] = React.useState<boolean>(false);

        React.useEffect(() => {
            if (props.runnerStatus === "Succeeded") {
                setEditing(false);
            } else if (props.runnerStatus === "Error") {
                props.state.reset();
            }
        }, [props.runnerStatus]);

        return <Container header={<Header variant="h3" actions={
            !editing &&
            <Box float="right">
                <Button
                    onClick={() => {
                        props.state.reset();
                        setEditing(true);
                    }}
                    variant="primary">
                    Edit
                </Button>
            </Box>}>
                Notification Preferences
            </Header>}>
                <Form
                    actions={editing && (
                        <div>
                            <Button onClick={() => {
                                props.state.reset();
                                setEditing(false);
                            }} variant="link">
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={props.onUpdate}
                                disabled={!props.state.isDirty()}
                                loading={props.runnerStatus === "Running"}>
                                Submit
                            </Button>
                        </div>
                )}>
                    <Box margin={{"left" : "m"}}>
                        <SpaceBetween size="m">
                            <ColumnLayout columns={2} borders="vertical">
                                <Toggle
                                    checked={props.state.notifyPageFreshnessReminders.value}
                                    onChange={( event: { detail: { checked: boolean } } ) =>
                                        props.state.notifyPageFreshnessReminders.setValue(event.detail.checked)
                                    }
                                    disabled={!editing}
                                >Enable Page Baseline Notifications</Toggle>
                                {context.hasLab(Preference.Lab.WhosMyLawyerFeature) && <SpaceBetween size="m">
                                    <Toggle
                                        checked={props.state.notifyLegalContactFreshnessReminders?.value}
                                        onChange={( event: { detail: { checked: boolean } } ) =>
                                            props.state.notifyLegalContactFreshnessReminders.setValue(event.detail.checked)
                                        }
                                        disabled={!editing}
                                    >Enable Legal Contact Baseline Notifications</Toggle>
                                    {props.state.notifyLegalContactFreshnessReminders?.value && <UIField.NumberValueField
                                        name="Default Legal Contact Freshness (days)"
                                        state={props.state.legalContactFreshness}
                                        editing={editing}
                                    />}
                                </SpaceBetween>}
                            </ColumnLayout>
                        </SpaceBetween>
                    </Box>
                </Form>
            </Container>;
    };

    export const OrganizationPreferences = (props: Props) => {
        const [editing, setEditing] = React.useState<boolean>(false);

        React.useEffect(() => {
            if (props.runnerStatus === "Succeeded") {
                setEditing(false);
            } else if (props.runnerStatus === "Error") {
                props.state.reset();
            }
        }, [props.runnerStatus]);

        return (
            <Container header={
                <Header variant="h3" actions={!editing &&
                    <Box float="right">
                    <Button
                        onClick={() => {
                            props.state.reset();
                            setEditing(true);
                        }}
                        variant="primary">
                        Edit
                    </Button>
                </Box>}>Organization</Header>
            }>
                <Form
                    actions={editing && (
                        <div>
                            <Button onClick={() => {
                                props.state.reset();
                                setEditing(false);
                            }} variant="link">
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={props.onUpdate}
                                disabled={!props.state.isDirty()}
                                loading={props.runnerStatus === "Running"}>
                                Submit
                            </Button>
                        </div>
                )}>
                    <FormField
                        label={<span>Organization</span>}
                        description="Organization logo will be displayed at top right corner on all PathFinder page">
                        <Select
                            disabled={!editing}
                            selectedOption={{
                                label: props.state.organization.value,
                                value: props.state.organization.value
                            }}
                            onChange={e =>
                                props.state.organization.setValue(e.detail.selectedOption.value)
                            }
                            options={[{
                                label: AdminPreferences.Organization.AMAZON_LEGAL,
                                value: AdminPreferences.Organization.AMAZON_LEGAL
                            }, {
                                label: AdminPreferences.Organization.AWS_LEGAL,
                                value: AdminPreferences.Organization.AWS_LEGAL
                            }]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </Form>
            </Container>);
    };

    export const RequestHomePageImageChange = () => {
        return (
            <Container header={
                <Header variant="h3">Request home page image change</Header>
            }>
                To change the default homepage image please submit a SIM to ALTAR using <a href="https://t.corp.amazon.com/create/templates/1f2c3d79-0fe1-4656-9b54-c42cef38b848" target="_blank">this template</a>.
                <br/><br/>
                <strong>Image Requirements:</strong>
                <ul>
                    <li>Must be a resolution around 2000 x 1000 pixels</li>
                    <li>Must be under 1mb in size</li>
                </ul>
            </Container>
        );
    };

    export const RequestPublicAccessSettingChange = () => {
        return (
            <Container
                header={<Header variant="h3">Request access to public view feature</Header>}
            >
                To opt-in/out of the public access feature for this instance, please submit a SIM to ALTAR using <a href="https://t.corp.amazon.com/create/templates/f6b766de-ed0a-4caa-9c9d-453802bc1a64" target="_blank">this template</a>.
                <br/><br/>
                <strong>Important Note:</strong>
                <p>
                    <ul>
                        <li>In the ticket, justify the use case for entitling global Amazon access to the instance.</li>
                        <li>Acknowledge the impact of public access controls and have mechanisms in place to handle data breach.</li>
                        <li>Enabling this setting will grant any Instance Admin to toggle the public setting in the <strong>Permissions</strong> tab.</li>
                    </ul>
                </p>
            </Container>
        );
    };
}