import * as React from "react";
import { Modal } from "react-bootstrap";
import "../../styles/component/Jodit/Content.scss";

export namespace BootstrapComp {
    export const CustomModal = (props: {
        visible: boolean;
        size: "sm" | "lg" | "xl";
        header?: any;
        body: any;
        footer?: JSX.Element;
        onDismiss: () => void;
    }) => {
        return (
            <Modal
                className="modal-container custom-map-modal"
                show={props.visible}
                enforceFocus={false}
                size={props.size}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={props.onDismiss}
                centered
                backdrop="static"
                keyboard={false}
                animation={true}
                scrollable={true}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {props.header}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.body}
                    </Modal.Body>
                    <Modal.Footer>
                        {props.footer}
                    </Modal.Footer>
            </Modal>
        );
    };
}