import {
    DecisionTreeContent,
    DecisionTreeNode,
    RichTextContent
} from "@amzn/ask-legal-domain";
import * as React from "react";
import { SmartStrongRichTextEdit } from "../../common/RichTextEditor/SmartStrongRichTextEdit";
import { UIModel } from "../../../model/ui-model";
import { UIField } from "../../common/UIField";

export const DecisionTreeContentEdit = (props: {
    state: UIModel.State<DecisionTreeContent>;
}) => {
    return <UIField.CustomField
        name="Welcome Text"
        child={<SmartStrongRichTextEdit
            content={!props.state.value ?
                DecisionTreeContent.create(
                    DecisionTreeNode.createRootNode({
                        nodeType: "SingleSelect",
                        containerId: ""
                    }), [], RichTextContent.create()).welcomeText.contentValue :
                props.state.value.welcomeText?.contentValue
            }
            onChange={(e) => {
                props.state.setValue(
                    DecisionTreeContent.update(props.state.value, RichTextContent.create(e))
                );
            }}
        />}
    />;
};