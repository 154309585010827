import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { LegalContactsPolarisFactory } from "./polaris/legal-contacts-polaris-factory";
import { ColumnDisplayPreference, LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { CollectionPreferencesProps, TableProps } from "@amzn/awsui-components-react";

export namespace WhosMyLawyerFactory {
    export const toOptionDefinition = (id: string) => {
        const definition = getSortSelectOptions().find(x => x.value === id);
        if (!definition) {
            return toOptionDefinition("legalContactUser.name");
        }
        return definition;
    };

    export function getSortSelectOptions(): OptionDefinition[] {
        return LegalContactsPolarisFactory.Table.toColumnDefinition().filter(y => y.sortingField).map(x => ({
            label: x.header,
            value: x.sortingField
        }));
    }

    export function toPreferences(content: WhosMyLawyerContent): CollectionPreferencesProps.Preferences<LegalContact.Record> {
        if (!content) return LegalContactsPolarisFactory.DEFAULT_PREFERENCES;
        return {
            pageSize: content.defaultConfiguration.pageSize || LegalContactsPolarisFactory.DEFAULT_PREFERENCES.pageSize,
            contentDisplay: content.defaultConfiguration.columnDisplay || LegalContactsPolarisFactory.DEFAULT_PREFERENCES.contentDisplay,
            stripedRows: content.defaultConfiguration.stripedRows || LegalContactsPolarisFactory.DEFAULT_PREFERENCES.stripedRows,
        };
    }

    export function toContentFromPreferences(
        prefs: CollectionPreferencesProps.Preferences<LegalContact.Record>,
        content?: WhosMyLawyerContent
    ): WhosMyLawyerContent {
        return WhosMyLawyerContent.create({
            sorting: content?.defaultConfiguration.sorting,
            pageSize: prefs.pageSize,
            columnDisplay: prefs.contentDisplay as ColumnDisplayPreference[],
            stripedRows: prefs.stripedRows
        });
    }

    export function getSortingStateFromContent(content: WhosMyLawyerContent): TableProps.SortingState<LegalContact.Record> {
        if (!content.defaultConfiguration.sorting) {
            return LegalContactsPolarisFactory.DEFAULT_SORTING_STATE;
        }
        return {
            sortingColumn: LegalContactsPolarisFactory.Table.getSortingColumnById(content.defaultConfiguration.sorting.field),
            isDescending: content.defaultConfiguration.sorting.order === "DESC"
        };
    }
}