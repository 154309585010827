import { InstanceFactory, PageLibrary } from "@amzn/ask-legal-domain";
import { Button, ColumnLayout, Container, Form, FormField, Header, SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import * as React from "react";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { useAPI } from "../../hooks/api-hook";
import { PageLibraryModel } from "../../model/page-library-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { UIField } from "../common/UIField";
import { useHistory } from "react-router-dom";
import { DeleteModal } from "../common/DeleteModal";

export const PageLibraryOverviewTab = (props: {
    state: UIModel.State<PageLibrary>;
}) => {
    const history = useHistory();
    const context = React.useContext(AppContext);
    const updatePageLibraryRunner = useAPI(
        context.getPageLibraryAPI().update
    );
    const updatePageLibraryState = PageLibraryModel.UpdateState.use({
        template: props.state.value
    });
    const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<boolean>(false);

    const update = () => {
        updatePageLibraryRunner.submitRun(
            PageLibraryModel.UpdateState.toInput(updatePageLibraryState)
        );
    };

    React.useEffect(() => {
        if (updatePageLibraryRunner.status === "Succeeded") {
            setEditing(false);
            props.state.setValue(updatePageLibraryRunner.data.output);
        } else if (updatePageLibraryRunner.status === "Error") {
            updatePageLibraryState.reset();
        }
    }, [updatePageLibraryRunner.status]);

    return (
        <React.Fragment>
            {updatePageLibraryRunner.status === "Error" && <ErrorFlashbar error={updatePageLibraryRunner.data.err} />}
            {deleteModal &&
                <DeleteModal
                    entityRef={props.state.value}
                    onCancel={() => setDeleteModal(false)}
                    onDeleted={() =>
                        history.push(`/instance/${InstanceFactory.fromEntityId(props.state.value.id)}/admin`)
                    }
                    customHeader="Delete Page Library"
                    customMessage={
                        <strong>
                            This operation will permanently delete all underlying pages and permissions.
                        </strong>
                    }
                />}
            <Container
                header={
                    <Header
                        variant="h3"
                        actions={
                            !editing &&
                            <SpaceBetween direction="horizontal" size="m">
                                <Button
                                    onClick={() => setDeleteModal(true)}
                                    variant="link">
                                    Delete
                                </Button>
                                <Button
                                    onClick={() => {
                                        updatePageLibraryState.reset();
                                        setEditing(true);
                                    }}
                                    variant="primary">
                                    Edit
                                </Button>
                            </SpaceBetween>
                        }>
                        Page Library Details
                    </Header>
                }>
                <ColumnLayout columns={1}>
                    <Form
                        actions={editing && (
                            <div>
                                <Button onClick={() => {
                                    updatePageLibraryState.reset();
                                    setEditing(false);
                                }} variant="link">
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={update}
                                    disabled={!updatePageLibraryState.isDirty()}
                                    loading={updatePageLibraryRunner.status === "Running"}>
                                    Submit
                                </Button>
                            </div>
                        )}>
                        <ColumnLayout columns={2}>
                            <SpaceBetween direction="vertical" size="l">
                                <UIField.StateValueField
                                    state={updatePageLibraryState.displayName}
                                    name="Display Name"
                                    constraintText={editing && `Maximum ${PageLibraryModel.TITLE_CHAR_LIMIT} characters`}
                                    editing={editing}
                                />
                                <FormField>
                                    <Toggle
                                        onChange={(e: { detail: { checked: boolean; }; }) => updatePageLibraryState.restrictToLegal.setValue(e.detail.checked)}
                                        checked={updatePageLibraryState.restrictToLegal.value}
                                        disabled={!editing}>
                                        Restrict to Legal Only
                                    </Toggle>
                                </FormField>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <UIField.StateValueField
                                    state={updatePageLibraryState.freshness}
                                    name="Page Freshness (days)"
                                    editing={editing}
                                />
                                <UIField.StateValueField
                                    state={updatePageLibraryState.permissonRequestTemplateUrl}
                                    constraintText={`Full URL including "https://" needs to be provided`}
                                    name="SIM Template URL for access request - OPTIONAL"
                                    editing={editing}
                                />
                            </SpaceBetween>
                        </ColumnLayout>
                    </Form>
                </ColumnLayout>
            </Container>
        </React.Fragment>
    );
};