import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import { RoleBasedPermissionedContentEditModule } from "../custom-modules/RoleBasedPermissionedContentModule";
import { DEFAULT_CONFIG } from "./tinymce-config/default";
import { TinyMCEBaseEditor } from "./TinyMCEBaseEditor";
import { AppContext } from "../../../../setup/context";
import { Preference } from "../../../../setup/preference";
import { DEV_MODE_CONFIG } from "./tinymce-config/dev-mode-config";

export const StrongRichTextEdit = (props: {
    content: string;
    onChange: (v: string) => void;
}) => {
    const context = React.useContext(AppContext);
    const permissionContentActive = UIModel.State.use<boolean>({ initialValue: false });
    const [editor, setEditor] = React.useState<any>(null);

    const getTinyConfig = () => {
        let baseConfig = DEFAULT_CONFIG;
        if (context.getPreference().labs.has(Preference.Lab.DevMode)) {
            baseConfig = DEV_MODE_CONFIG;
        }
        const config = {
            ...baseConfig,
            toolbar: baseConfig.toolbar + "|permissionedContentButton",
            paste_preprocess: (plugin, args) => {
                let restoreHtml = args.content;
                restoreHtml = restoreHtml.replaceAll("&lt;", "<");
                restoreHtml = restoreHtml.replaceAll("&gt;", ">");
                restoreHtml = restoreHtml.replaceAll("&quot;", `"`);
                args.content = restoreHtml;
            },
            setup: e => {
                e.ui.registry.addButton("permissionedContentButton", {
                    icon: "lock",
                    text: "Permissioned Content",
                    onAction: val => permissionContentActive.setValue(true)
                });
            }
        };
        return config;
    };
    return (
        <React.Fragment>
            <RoleBasedPermissionedContentEditModule active={permissionContentActive} editor={editor}/>
            <TinyMCEBaseEditor
                onChange={props.onChange}
                value={props.content}
                onInit={e => setEditor(e)}
                config={getTinyConfig()}
            />
        </React.Fragment>
    );
};