import {
    EntityFactory,
    EntityRef,
    PageLibraryInterface,
    PageLibrary,
    EntityType
  } from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import * as React from "react";
import { Builder } from "builder-pattern";

export namespace PageLibraryModel {
    export const TITLE_CHAR_LIMIT = 50;

    export class CreateState {
        name: UIModel.State<string>;
        displayName: UIModel.State<string>;
        freshness: UIModel.State<string>;
        instanceRef: EntityRef;
        restrictToLegal: UIModel.State<boolean>;
        enableApprovalWorkflow: UIModel.State<boolean>;
        permissionRequestTemplateUrl: UIModel.State<string>;
        errorText: string;
        reset: () => void;

        static toInput (state: CreateState): PageLibraryInterface.CreatePageLibraryInput {
            return PageLibraryInterface.CreatePageLibraryInput.create(
                state.instanceRef,
                state.name.value,
                state.displayName.value,
                parseInt(state.freshness.value),
                state.restrictToLegal.value,
                state.enableApprovalWorkflow.value,
                state.permissionRequestTemplateUrl.value
            );
        }

        static use (props: {
            template?: PageLibraryInterface.CreatePageLibraryInput
            instanceId: string
        }): CreateState {
            const name = UIModel.State.useWithRegexAndCharLimit({
                regex: new RegExp(/^[a-zA-Z0-9]+$/),
                characterLimit: TITLE_CHAR_LIMIT
            });
            const displayName = UIModel.State.useNotNullStringWithCharLimit({ characterLimit: TITLE_CHAR_LIMIT });
            const freshness = UIModel.State.use<string>({
                initialValue: "30"
            });

            const restrictToLegal = UIModel.State.use<boolean>({
                initialValue: false
            });

            const enableApprovalWorkflow = UIModel.State.use<boolean>({
                initialValue: false
            });

            const permissionRequestTemplateUrl = UIModel.State.use<string>({
                initialValue: ""
            });

            const reset = () => {
                name.setValue(null);
                displayName.setValue(null);
                freshness.setValue("30");
                restrictToLegal.setValue(false);
                enableApprovalWorkflow.setValue(false);
                permissionRequestTemplateUrl.setValue("");
            };

            React.useEffect(() => {
                if (!props.template) return;
                name.setValue(props.template.name);
                displayName.setValue(props.template.displayName);
                freshness.setValue(props.template.freshness.toString());
                restrictToLegal.setValue(props.template.restrictToLegal);
                enableApprovalWorkflow.setValue(props.template.enableApprovalWorkflow);
                permissionRequestTemplateUrl.setValue(props.template.permissionRequestTicketTemplate);
            }, []);

            return Builder<CreateState>(new CreateState())
                .name(name)
                .displayName(displayName)
                .freshness(freshness)
                .restrictToLegal(restrictToLegal)
                .enableApprovalWorkflow(enableApprovalWorkflow)
                .permissionRequestTemplateUrl(permissionRequestTemplateUrl)
                .instanceRef(
                    EntityFactory.fromEntityAttributes(props.instanceId, EntityType.Instance, 1)
                )
                .reset(reset)
                .build();
        }
    }

    export class UpdateState {
        targetRef: EntityRef;
        displayName: UIModel.State<string>;
        freshness: UIModel.State<string>;
        restrictToLegal: UIModel.State<boolean>;
        enableApprovalWorkflow: UIModel.State<boolean>;
        permissonRequestTemplateUrl: UIModel.State<string>;
        errorText: string;
        reset: () => void;
        isDirty: () => boolean;

        static toInput (state: UpdateState): PageLibraryInterface.UpdatePageLibraryInput {
            return PageLibraryInterface.UpdatePageLibraryInput.create(
                state.targetRef,
                {
                    displayName: state.displayName.value,
                    freshness: parseInt(state.freshness.value),
                    restrictToLegal: state.restrictToLegal.value,
                    enableApprovalWorkflow: state.enableApprovalWorkflow.value ?? false,
                    permissionRequestTicketTemplate: state.permissonRequestTemplateUrl.value ?? ""
                }
            );
        }

        static use (props: {
            template: PageLibrary
        }): UpdateState {
            const displayName = UIModel.State.useNotNullStringWithCharLimit({ characterLimit: TITLE_CHAR_LIMIT });
            const freshness = UIModel.State.useNotNullString({});
            const restrictToLegal = UIModel.State.useRequired<boolean>({});
            const enableApprovalWorkflow = UIModel.State.useRequired<boolean>({});
            const permissionRequestTicketTemplateUrl = UIModel.State.use<string>({});

            const reset = () => {
                displayName.setValue(props.template.name);
                freshness.setValue(props.template.freshness.toString());
                restrictToLegal.setValue(restrictToLegal.value);
                enableApprovalWorkflow.setValue(props.template.enableApprovalWorkflow);
                permissionRequestTicketTemplateUrl.setValue(props.template.permissionRequestTicketTemplate);
            };

            const isDirty = () => {
                if (
                    displayName.value === props.template.name &&
                    freshness.value === props.template.freshness.toString() &&
                    restrictToLegal.value === props.template.restrictToLegal &&
                    enableApprovalWorkflow.value === props.template.enableApprovalWorkflow &&
                    permissionRequestTicketTemplateUrl.value === props.template.permissionRequestTicketTemplate
                ) return false;
                return true;
            };

            React.useEffect(() => {
                displayName.setValue(props.template.name);
                freshness.setValue(props.template.freshness.toString());
                restrictToLegal.setValue(props.template.restrictToLegal);
                enableApprovalWorkflow.setValue(props.template.enableApprovalWorkflow);
                permissionRequestTicketTemplateUrl.setValue(props.template.permissionRequestTicketTemplate);
            }, []);

            return Builder<UpdateState>(new UpdateState())
                .targetRef(EntityFactory.toEntityRef(props.template))
                .displayName(displayName)
                .freshness(freshness)
                .restrictToLegal(restrictToLegal)
                .enableApprovalWorkflow(enableApprovalWorkflow)
                .permissonRequestTemplateUrl(permissionRequestTicketTemplateUrl)
                .reset(reset)
                .isDirty(isDirty)
                .build();
        }
    }
}