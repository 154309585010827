import { Jodit } from "jodit";
import { closest, nextSiblings } from "../utils";
const INDENT_INCREASE_ICON = require("../../../../../assets/indent_increase_icon.png").default;
const INDENT_DECREASE_ICON = require("../../../../../assets/indent_decrease_icon.png").default;

const CUSTOM_INDENTATION = {
    customIndentIncrease: {
        name: "list-increase-indent",
        iconURL: String(INDENT_INCREASE_ICON),
        tooltip: "Increase list indent",
        exec: function (editor: Jodit) {
        const current: Node = editor.selection.current(false) as Node;
        if (!current) { // No element selected
            return;
        }
        // Get currently selected list item (li)
        const currentListItemElement = closest("li", current, editor);
        if (!currentListItemElement) { // No current list item element
            return;
        }
        // Get currently selected list (ol/ul)
        const currentListElement = closest("ul|ol", currentListItemElement, editor);
        if (!currentListElement ) { // No current list element
            return;
        }
        // Get previous list item to append item to sub list of that item.
        const previousListItemElement: Element = (currentListItemElement as Element).previousElementSibling;
        if (!previousListItemElement) { // No previous sibling
            return;
        }
        // Store snapshot to restore after having moved element
        editor.selection.save();
        // Check if previous list item already contains a list
        let childListElement = previousListItemElement.querySelector("ol,ul");
        // Create new list if previous item does not include any list
            childListElement = childListElement || editor.create.element(currentListElement.nodeName);
            childListElement.appendChild(currentListItemElement);
            previousListItemElement.appendChild(childListElement);
            editor.selection.restore();
        }
    },
    customIndentDecrease: {
        name: "list-decrease-indent",
        iconURL: String(INDENT_DECREASE_ICON),
        tooltip: "Decrease list indent",
        exec: function (editor: Jodit) {
            const current: Node = editor.selection.current(false) as Node;
            if (!current) { // No element selected
                return;
            }
            // Get currently selected list item (li)
            const currentListItemElement = closest("li", current, editor);
            if (!currentListItemElement) { // No current list element
                return;
            }
            // Get currently selected list (ul/ol)
            const currentListElement = closest("ol|ul", current, editor);
            // Get parent list item of selected list (li)
            // Return if not nested.
            const parentListItemElement = closest("li", currentListElement, editor);
            if (!parentListItemElement) { // Not nested
                return;
            }
            // Get parent list
            const parentListElement = closest("ol|ul", parentListItemElement, editor);
            if (!parentListElement) { // Not nested
                return;
            }
            // Store snapshot to restore after having moved element
            editor.selection.save();
            // Add all next siblings of current list item to current list item sub-list.
            const currentListItemNextSiblings = nextSiblings(currentListItemElement);
            if (currentListItemNextSiblings.length) {
                // Check if list item already contains a list
                let childListElement: Node = (currentListItemElement as Element).querySelector("ol,ul");
                childListElement = childListElement || editor.create.element(currentListElement.nodeName);
                currentListItemElement.appendChild(childListElement);
                for (const currentListItemNextSibling of currentListItemNextSiblings) {
                childListElement.appendChild(currentListItemNextSibling);
                }
            }
            // Insert current list item (li) into parent list at the correct position
            parentListElement.insertBefore(currentListItemElement, (parentListItemElement as Element).nextElementSibling);
            // Check if previous list is empty, remove if so
            if (!currentListElement.childNodes.length) {
                parentListItemElement.removeChild(currentListElement);
            }
            editor.selection.restore();
        }
    }
};

export const ENHANCED_WRITE_CONFIG = {
    ...Jodit.defaultOptions,
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    buttons:
        "bold, strikethrough, underline, italic | font, fontsize, brush | indent, outdent | ul, ol, customIndentIncrease, customIndentDecrease | image, table, lineHeight, link | align,undo,redo,\n,selectall,cut,copy,paste,eraser,copyformat,|,hr,symbol",
    uploader: {
        // ...Jodit.defaultOptions.uploader,
        insertImageAsBase64URI: true
    },
    editorCssClass: "diff",
    style: {
        fontFamily: "Amazon Ember"
    },
    enter: "br",
    controls: CUSTOM_INDENTATION
};
