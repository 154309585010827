import * as React from "react";
import {
    Alert,
    Box,
    Button,
    Flashbar,
    Link,
    Modal,
    Popover,
    ProgressBar,
    SpaceBetween,
    TextContent,
    Toggle
} from "@amzn/awsui-components-react";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { EntitySelection } from "../common/EntitySelection";
import { EntityRef, InstanceFactory, PageFactory, PageLibrary } from "@amzn/ask-legal-domain";
import { UIField } from "../common/UIField";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { useHistory } from "react-router-dom";
import { PageLibraryLink } from "../pageLibrary/PageLibraryLink";
import { UIModel } from "../../model/ui-model";
import { PageModel } from "../../model/page-model";

export const DeepCopyPageModal = (props: {
    onDismiss: () => void;
    pageId: string;
    anticipatedExecutionTime?: number;
}) => {
    const history = useHistory();
    const context = React.useContext(AppContext);
    const [targetPageLibrary, setTargetPageLibrary] = React.useState<EntityRef>(null);
    const targetPageName = UIModel.State.useWithRegexAndCharLimit({
        initialValue: PageFactory.getNameFromPageId(props.pageId),
        regex: new RegExp(/^[a-zA-Z0-9]+$/),
        characterLimit: PageModel.TITLE_CHAR_LIMIT
    });
    const [deprecateSourcePage, setDeprecateSourcePage] = React.useState<boolean>(false);
    const [progressBarValue, setProgressBarValue] = React.useState<number>(0);
    const [redirectionCountDown, setRedirectionCountDown] = React.useState<number>(5);
    const deepCopyPageRunner = useAPI2(
        context.getPageAPI().deepCopy
    );
    const triggerPageCopy = () => {
        deepCopyPageRunner.invoke({
            sourcePageId: props.pageId,
            targetPageId: PageFactory.toPageId(targetPageLibrary.id, targetPageName.value),
            deprecateSourcePage: deprecateSourcePage
        });
    };

    React.useEffect(() => {
        let interval = null;
        let countdown = null;
        let delayedRedirection = null;
        if (deepCopyPageRunner.status === "Running") {
            interval = setInterval(() => {
                setProgressBarValue((prevValue) => {
                    if (prevValue < 90) return prevValue + 10;
                    else return 99;
                });
            }, 1000);
        } else if (deepCopyPageRunner.status === "Succeeded") {
            setProgressBarValue(100);
            countdown = setInterval(() => {
                if (redirectionCountDown > 0) {
                    setRedirectionCountDown((prevValue) => prevValue - 1);
                }
            }, 1000);

            delayedRedirection = setTimeout(() => {
                history.push(`/page/${deepCopyPageRunner.output.newPageRef.id}/live`);
            }, 5000);
        }
        return () => {
            clearTimeout(delayedRedirection);
            clearInterval(countdown);
            clearInterval(interval);
        };
      }, [deepCopyPageRunner.status]);

    return (
        <Modal
            visible={true}
            size="medium"
            header="Copy page"
            onDismiss={props.onDismiss}
            footer={
                <Box float="right">
                    <Button variant="link"
                        loading={deepCopyPageRunner.status === "Running"}
                        onClick={props.onDismiss}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary"
                        loading={deepCopyPageRunner.status === "Running"}
                        disabled={
                            !targetPageLibrary ||
                            targetPageName.errorText ||
                            (props.pageId === PageFactory.toPageId(targetPageLibrary.id, targetPageName.value)) ||
                            deepCopyPageRunner.status === "Succeeded"
                        }
                        onClick={triggerPageCopy}
                    >
                        Confirm
                    </Button>
                </Box>
            }>
            {deepCopyPageRunner.status === "New" &&
            <SpaceBetween size="xs">
                <UIField.CustomField
                    name="Please select a page library to copy the page to:"
                    child={<EntitySelection.SingleWithPagination<PageLibrary>
                        api={context.getPageLibraryAPI().loadAll}
                        partitionKey={InstanceFactory.fromEntityId(props.pageId)}
                        pageSize={20}
                        selected={targetPageLibrary}
                        onSelectionChange={e => setTargetPageLibrary(e)}
                    />}
                />
                <UIField.StateValueField
                    name="New Page Name (part of the URL)"
                    state={targetPageName}
                    constraintText={
                        <React.Fragment>
                            Maximum {PageModel.TITLE_CHAR_LIMIT} characters <Popover
                            dismissAriaLabel="Close"
                            header="Page Naming Guidelines"
                            triggerType="custom"
                            content={<React.Fragment>
                                    <small>
                                        <ul>
                                            <li>Cannot contain spaces</li>
                                            <li>Cannot contain any special characters</li>
                                            <li>Can contain alphabets <b>A...Z</b> <b>a...z</b></li>
                                            <li>Can contain numbers <b>0-9</b></li>
                                            <li><b><em>Examples : </em></b> SamplePageName, 1New2Document3, ... etc</li>
                                        </ul>
                                    </small>
                                </React.Fragment>}
                            >
                                <Link variant="info">Format</Link>
                            </Popover></React.Fragment>}
                    editing={true}
                />
                <Toggle
                    checked={deprecateSourcePage}
                    onChange={(e: { detail: { checked: boolean; }; }) => setDeprecateSourcePage(e.detail.checked)}
                >
                    Deprecate current page
                </Toggle>
            </SpaceBetween>}
            {targetPageLibrary &&
                <Box padding={{ top: "m" }}>
                    <TextContent>Destination Page Library: <PageLibraryLink external id={targetPageLibrary.id}/><br/></TextContent>
                </Box>}
            {targetPageLibrary && !targetPageName.errorText &&
                <Box padding={{ top: "m", bottom: "m" }}>
                    <TextContent>Destination Page URL: <small>/page/{PageFactory.toPageId(targetPageLibrary.id, targetPageName.value)}/live</small><br /></TextContent>
                </Box>
            }
            {(deepCopyPageRunner.status === "Running" || deepCopyPageRunner.status === "Succeeded") && <ProgressBar
                value={progressBarValue}
                additionalInfo="Please do not refresh the page nor close the tab..."
                description="Please wait patiently while copying is in progress"
                label="Copying in progress"
            />}
            {deepCopyPageRunner.status === "Succeeded" && <Flashbar items={[{
                type: "success",
                content: <div>Copying was successful, you will be redirected in {redirectionCountDown} seconds.</div>,
                dismissible: false,
                id: "message_1"
            }]}/> }
            {deepCopyPageRunner.status === "Error" && <ErrorFlashbar error={deepCopyPageRunner.err}/> }
        </Modal>
    );
};