import * as React from "react";
import { AppContext } from "../../../setup/context";
import { Preference } from "../../../setup/preference";
import { RestrictedHyperLinkOnlyRichTextEdit as JoditBasedRichTextEdit } from "./JoditBased/RestrictedHyperLinkOnlyRichTextEdit";
import { RestrictedHyperLinkOnlyRichTextEdit as TinyMCEBasedRichTextEdit } from "./TinyMCEBased/RestrictedHyperLinksOnlyEdit";

export const SmartRestrictedHyperlinksOnlyRichTextEdit = (props: {
    content: string;
    onChange: (v: string) => void;
}) => {
    const context = React.useContext(AppContext);

    if (true) {
        return <TinyMCEBasedRichTextEdit content={props.content} onChange={props.onChange}/>;
    } else {
        return <JoditBasedRichTextEdit content={props.content} onChange={props.onChange}/>;
    }
};