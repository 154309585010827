import {
    Container,
    ContainerFactory,
    ContainerRef,
    EntityFactory,
    IContainer,
    IPageDraft
} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ExpandableSection,
    Flashbar,
    FlashbarProps,
    Header,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { PageDraftModel } from "../../model/page-draft-model";
import { UIModel } from "../../model/ui-model";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { ContainerDiffView } from "./ContainerDiffView";
import { CustomCommonContainerModal } from "./CommonContainerModal";
import { RemoveContainerConfirmModal } from "./modal-content/RemoveContainerConfirmModal";
import { DocumentUploadFileModal } from "./modal-content/DocumentFileUploadModal";
import { ViewContainerContent } from "./modal-content/ViewContainerContent";
import { Preference } from "../../setup/preference";
import {
    getObjectSizeinBytes,
    getQueryParameterMap,
    isCompareFeatureActivated,
    scrollWithDelayInMs,
    StringUtils,
    UIConstants
} from "../common/common-utils";
import { ContainerEditModeHeader } from "./container-expandable-header/ContainerEditModeHeader";
import { ContainerButtonDropdown, ContainerManageModeHeader } from "./container-expandable-header/ContainerManageModeHeader";
import { ContainerReviewModeHeader } from "./container-expandable-header/ContainerReviewModeHeader";
import { EditContainerContent } from "./modal-content/EditContainerContent";
import "../../styles/custom-container-header.scss";
import { useHistory } from "react-router-dom";

export namespace ContainerEdit {
    export const Preload = (props: {
        id: string;
        activeEditContainerId: UIModel.State<string>;
        updateOrderState: PageDraftModel.ReorderContainerState;
        liveContainerRef?: ContainerRef;
        onLoadComplete?: (loadedData: IContainer.LoadContainerOutput) => void;
    }) => {
        const context = React.useContext(AppContext);
        const loadContainerRunner = useAPI(
            context.getContainerAPI().load
        );
        const containerDataState = UIModel.State.use<IContainer.LoadContainerOutput>({
            initialValue: null
        });

        React.useEffect(() => {
            loadContainerRunner.submitRun(props.id);
        }, [props.id]);

        React.useEffect(() => {
            if (loadContainerRunner.status === "Succeeded") {
                containerDataState.setValue(
                    loadContainerRunner.data.output
                );
                if (!props.onLoadComplete) return;
                props.onLoadComplete(loadContainerRunner.data.output);
            }
        }, [loadContainerRunner.status]);

        return (
            <React.Fragment>
                <Box margin={{ bottom: "s", top: "s" }}>
                    {loadContainerRunner.status === "Succeeded" && !!containerDataState.value &&
                        <Comp
                            state={containerDataState}
                            reload={() => {
                                containerDataState.setValue(null);
                                loadContainerRunner.reload();
                            }}
                            activeEditContainerId={props.activeEditContainerId}
                            updateOrderState={props.updateOrderState}
                            liveContainerRef={props.liveContainerRef}
                        />
                    }
                    {loadContainerRunner.status === "Error" &&
                        <ExpandableSection variant="container" className="custom-container" defaultExpanded={true} header={<Header><p className="dark-header-p">Error Loading Container</p></Header>}>
                            Error Loading Container
                        </ExpandableSection>
                    }
                    {(loadContainerRunner.status === "Running" || loadContainerRunner.status === "New") &&
                        <ExpandableSection variant="container" className="custom-container" defaultExpanded={true} header={<Header><p className="dark-header-p">Loading...</p></Header>}>
                            <Spinner />
                        </ExpandableSection>
                    }
                </Box>
            </React.Fragment>
        );
    };

    export const Comp = (props: {
        state: UIModel.State<IContainer.LoadContainerOutput>;
        updateOrderState: PageDraftModel.ReorderContainerState;
        activeEditContainerId: UIModel.State<string>;
        liveContainerRef?: ContainerRef;
        reload: () => void;
    }) => {
        const context = React.useContext(AppContext);
        const appHistory = useHistory<any>();
        const [deleting, setDeleting] = React.useState<boolean>(false);
        const [expandFlag_1, setExpandFlag_1] = React.useState<boolean>(false);
        const [expandFlag_2, setExpandFlag_2] = React.useState<boolean>(false);
        const [expanded, setExpanded] = React.useState<boolean>(true);
        const showDiffState = UIModel.State.use<boolean>({initialValue: false});
        const [liveContainerData, setLiveContainerData] = React.useState<any>();
        const [containerStatus, setContainerStatus] = React.useState<FlashbarProps.MessageDefinition[]>([]);

        const [customSize, setCustomSize] = React.useState<"sm" | "lg" | "xl">("lg");
        const [fileUploading, setFileUploading] = React.useState<boolean>(false);

        const editContainerState = PageDraftModel.EditContainerState.use({
            draftRef: EntityFactory.toEntityRef(
                props.updateOrderState.draftField.value
            )
        });

        const editContainerRunner = useAPI(
            context.getPageDraftAPI().editContainer
        );
        const removeContainerRunner = useAPI(
            context.getPageDraftAPI().removeContainer
        );
        const loadContainerVersionRunner = useAPI(
            context.getContainerAPI().loadVersion
        );

        const edit = () => {
            const editPayload = PageDraftModel.EditContainerState.toEditContainerInput(
                editContainerState
            );
            const payloadSize = getObjectSizeinBytes(editPayload);
            if (payloadSize > UIConstants.MAX_EDIT_CONTAINER_SIZE_MB * 1024 * 1024) {
                setContainerStatus([
                    {
                        type: "error",
                        header: `Container size limit exceeded [ ${StringUtils.prettyPrintDecimal(payloadSize / (1024 * 1024))} MB / ${UIConstants.MAX_EDIT_CONTAINER_SIZE_MB} MB ]`,
                        content: `Learn how to resolve this issue here: ${UIConstants.MAX_EDIT_CONTAINER_LIMIT_DOCUMENTATION}`,
                        dismissible: true,
                        onDismiss:  () => {
                            setContainerStatus([]);
                        }
                    }
                ]);
                return;
            }
            setContainerStatus([]);
            editContainerRunner.submitRun(
                editPayload
            );
            navigator.clipboard.writeText("");
        };

        const remove = () => {
            removeContainerRunner.submitRun(
                IPageDraft.RemoveContainerInput.create({
                    containerRef: ContainerFactory.toContainerRef(props.state.value.containerMetadata),
                    draftRef: EntityFactory.toEntityRef(props.updateOrderState.draftField.value)
                })
            );
        };

        const clearURLEditMode = () => {
            const newWindowhash = window.location.hash.split("&").filter(x => !x.includes("edit=true")).join("&");
            history.replaceState(null, "", newWindowhash);
        };

        const buttonDropdownEventHandler = (buttonId: string) => {
            switch (buttonId) {
                case ContainerButtonDropdown.Edit.id:
                    editContainerState.init({
                        container: props.state.value.containerMetadata,
                        containerContent: props.state.value.loadedContent
                    });
                    props.activeEditContainerId.setValue(
                        props.state.value.containerMetadata.id
                    );
                    break;
                case ContainerButtonDropdown.Delete.id:
                    setDeleting(true);
                    break;
                case ContainerButtonDropdown.AddGroup.id:
                    alert("Not Implemented");
                    break;
                case ContainerButtonDropdown.AddQuestion.id:
                    alert("Not Implemented");
                    break;
                case ContainerButtonDropdown.Moveup.id:
                    props.updateOrderState.moveUp(
                        props.state.value.containerMetadata
                    );
                    break;
                case ContainerButtonDropdown.Movedown.id:
                    props.updateOrderState.moveDown(
                        props.state.value.containerMetadata
                    );
                    break;
                case ContainerButtonDropdown.Upload.id:
                    // Mechanism to open upload file modal
                    editContainerState.init({
                        container: props.state.value.containerMetadata,
                        containerContent: props.state.value.loadedContent
                    });
                    setFileUploading(true);
                    break;
                case ContainerButtonDropdown.EditDecisionTree.id:
                    // Redirect to Decision Tree Editor
                    appHistory.push(`/decision-tree/${
                        props.state.value.containerMetadata.id
                    }/edit?draftId=${
                        props.updateOrderState.draftField.value.id
                    }&draftVersion=${
                        props.updateOrderState.draftField.value.version
                    }`);
                    break;
            }
        };

        React.useEffect(() => {
            if (
                !!props.liveContainerRef &&
                props.liveContainerRef.version !== props.state.value.containerMetadata.version &&
                isCompareFeatureActivated(props.state.value.containerMetadata.containerType) // Control Diff by container type
            ) loadContainerVersionRunner.submitRun(props.liveContainerRef);
        }, []);

        React.useEffect(() => {
            let currentExpanded = expanded;
            if (!!expandFlag_1) {
                currentExpanded = !currentExpanded;
                setExpandFlag_1(false);
            }
            if (!!expandFlag_2) {
                currentExpanded = !currentExpanded;
                setExpandFlag_2(false);
            }
            setExpanded(currentExpanded);
        }, [expandFlag_1, expandFlag_2]);

        React.useEffect(() => {
            if (editContainerRunner.status === "Succeeded") {
                editContainerState.init(null);
                props.updateOrderState.setDraft(editContainerRunner.data.output);
                props.reload();
                props.activeEditContainerId.setValue("");
            }
        }, [editContainerRunner.status]);

        React.useEffect(() => {
            if (removeContainerRunner.status === "Succeeded") {
                setDeleting(false);
                props.updateOrderState.setDraft(removeContainerRunner.data.output);
            }
        }, [removeContainerRunner.status]);

        React.useEffect(() => {
            if (loadContainerVersionRunner.status === "Succeeded") {
                setLiveContainerData(loadContainerVersionRunner.data.output);
            }
        }, [loadContainerVersionRunner.status]);

        React.useEffect(() => {
            if (!editContainerState || !editContainerState.activeContainerField.value) return;
            switch (editContainerState.activeContainerField.value.containerType) {
                case Container.Type.RICH_TEXT: setCustomSize("xl"); break;
                default: setCustomSize("lg");
            }
        }, [editContainerState.activeContainerField.value]);

        React.useEffect(() => {
            const queryParamMap = getQueryParameterMap();
            if (queryParamMap.get("container")) {
                const targetContainerId = queryParamMap.get("container");
                if (props.state.value.containerMetadata.id !== targetContainerId) return;
                if (queryParamMap.get("edit") === "true") {
                    editContainerState.init({
                        container: props.state.value.containerMetadata,
                        containerContent: props.state.value.loadedContent
                    });
                    props.activeEditContainerId.setValue(
                        props.state.value.containerMetadata.id);
                    clearURLEditMode();
                }
                scrollWithDelayInMs(700, targetContainerId);
            }
        }, []);

        const isEditingCurrentContainer = () => {
            return props.activeEditContainerId.value ===
                props.state.value.containerMetadata.id;
        };

        const isEditing = () => {
            return !StringUtils.isEmpty(props.activeEditContainerId.value);
        };

        const getExpandableHeader = () => {
            const baseProps = {
                onHeaderClicked: () => setExpandFlag_2(true),
                containerMetaData: props.state.value.containerMetadata,
                loading: editContainerRunner.status === "Running"
            };
            if (isEditing()) {
                return <ContainerEditModeHeader.Comp
                    {...baseProps}
                />;
            } else if (props.updateOrderState.draftField.value.status === "DRAFT") {
                return <ContainerManageModeHeader.Comp
                    showDiff={showDiffState}
                    buttonDropdownEventHandler={buttonDropdownEventHandler}
                    showCompareFeature={(
                        !props.liveContainerRef ||
                        props.liveContainerRef.version !== props.state.value.containerMetadata.version
                    ) && isCompareFeatureActivated(props.state.value.containerMetadata.containerType)}
                    loading={loadContainerVersionRunner.status === "Running"}
                    {...baseProps}
                />;
            } else {
                return <ContainerReviewModeHeader.Comp
                    showDiff={showDiffState}
                    {...baseProps}
                />;
            }
        };

        return (
            <React.Fragment>
                {editContainerRunner.status === "Error" && <ErrorFlashbar error={editContainerRunner.data.err} />}
                {removeContainerRunner.status === "Error" && <ErrorFlashbar error={removeContainerRunner.data.err} />}
                <ExpandableSection
                    variant="container"
                    className="custom-container"
                    onChange={() => setExpandFlag_1(true)}
                    expanded={expanded}
                    header={getExpandableHeader()}>
                    {context.hasLab(Preference.Lab.InPlaceContainerEditing) && isEditingCurrentContainer() ? (
                        // below inline css styles to fix https://t.corp.amazon.com/P80069650, they are not re-usable so no need to move to css file
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <EditContainerContent state={editContainerState} />
                            <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "10px" }}>
                                <Button
                                    variant="link"
                                    loading={editContainerRunner.status === "Running"}
                                    onClick={() => {
                                        editContainerState.init(null);
                                        setFileUploading(false);
                                        navigator.clipboard.writeText("");
                                        props.activeEditContainerId.setValue("");
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    loading={editContainerRunner.status === "Running"}
                                    onClick={edit}
                                    disabled={!editContainerState.validState}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                        {!showDiffState.value &&
                            <ViewContainerContent
                                containerMetadata={props.state.value.containerMetadata}
                                content={props.state.value.loadedContent}
                                showEditControls={true}
                            />
                        }
                        {!!showDiffState.value &&
                            <ContainerDiffView
                                newContainer={props.state.value}
                                oldContainer={liveContainerData}
                            />
                        }</React.Fragment>
                    )}
                </ExpandableSection>
                {(
                    !!editContainerState.activeContainerField.value &&
                    !context.hasLab(Preference.Lab.InPlaceContainerEditing) &&
                    !fileUploading
                ) && (
                    <CustomCommonContainerModal
                        header="Edit Container"
                        child={<SpaceBetween size="xs" direction="vertical">
                            <Flashbar items={containerStatus} />
                            <EditContainerContent state={editContainerState} />
                        </SpaceBetween>}
                        onSave={edit}
                        loading={editContainerRunner.status === "Running" || removeContainerRunner.status === "Running"}
                        onCancel={() => {
                            editContainerState.init(null);
                            setFileUploading(false);
                            navigator.clipboard.writeText("");
                            props.activeEditContainerId.setValue("");
                            setContainerStatus([]);
                        }}
                        size={customSize}
                        disabled={!editContainerState.validState}
                    />
                )}
                {(!!editContainerState.activeContainerField.value && !!fileUploading) && (
                    <DocumentUploadFileModal
                        containerId={props.state.value.containerMetadata.id}
                        draftRef={EntityFactory.toEntityRef(props.updateOrderState.draftField.value)}
                        loading={editContainerRunner.status === "Running" || removeContainerRunner.status === "Running"}
                        onClose={() => {
                            props.reload();
                            editContainerState.init(null);
                            setFileUploading(false);
                            navigator.clipboard.writeText("");
                        }}
                    />
                )}
                {deleting &&
                    <RemoveContainerConfirmModal
                        container={props.state.value.containerMetadata}
                        visible={deleting}
                        loading={removeContainerRunner.status === "Running" || editContainerRunner.status === "Running"}
                        onDismiss={() => setDeleting(false)}
                        onDelete={remove}
                    />
                }
            </React.Fragment>
        );
    };
}