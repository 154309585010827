import { Editor } from "@tinymce/tinymce-react";
import * as React from "react";
import { AppContext } from "../../../../setup/context";
import "../../../../styles/component/Jodit/Content.scss";

export const TinyMCEBaseEditor = (props: {
    value: string,
    onChange?: (v: string) => void,
    onInit?: (e: any) => void;
    config: any,
    disabled?: boolean
}) => {
    const context = React.useContext(AppContext);
    let config = props.config;

    // const TINYMCE_PREMIUM_UAT = [
    //     "powerpaste",
    //     "advtable",
    //     "tinymcespellchecker",
    //     "advlist",
    //     "checklist",
    //     "a11ychecker",
    //     "autocorrect"
    // ];
    return <Editor
        tinymceScriptSrc="./node_modules/tinymce/tinymce.min.js"
        onInit={(_, e) => {
            if (!!props.onInit) props.onInit(e);
        }}
        disabled={props.disabled}
        value={props.value}
        onEditorChange={e => {
            if (props.onChange) props.onChange(e);
        }}
        init={config}
    />;
};