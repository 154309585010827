import { Box, ColumnLayout, Link, Popover } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIField } from "../common/UIField";
import { UserSearch } from "../common/UserSearch";
import { LegalContactModel } from "../../model/legal-contact-model";
import { InputTokens } from "../common/InputTokens";
import { ConfirmLeavePageModal } from "../common/ConfirmLeavePageModal";

export const EditLegalContactForm = (props: {
    state: LegalContactModel.UpdateState
}) => {
    return (
        <>
        <ConfirmLeavePageModal showModal={true} />
            <ColumnLayout columns={2}>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Legal Contact"
                        errorText={props.state.legalContactUser.errorText}
                        child={
                            <UserSearch.Single
                                selected={props.state.legalContactUser.value}
                                onUserSelectChange={(selected) => props.state.legalContactUser.setValue(selected)}
                            />
                        }
                    />
                </div>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Business Leader(s)"
                        helpInfo={<Popover
                            dismissAriaLabel="Close"
                            header="Legal Contact's Business Leaders"
                            triggerType="custom"
                            content={<Box color="text-body-secondary">
                                Select the L8 and/or L10 leaders of the organizations for which they are a primary business line legal contact.
                                <br /><br />
                                Business partners using this tool will receive recommendations on which legal contact to reach out to based on the leaders entered here.
                            </Box>}
                        >
                            <Link variant="info">Need Help?</Link>
                        </Popover>}
                        errorText={props.state.businessLeader.errorText}
                        child={
                            <UserSearch.Multiple
                                initialSelected={props.state.businessLeader.value}
                                onUserSelectChange={(selected) => props.state.businessLeader.setValue(selected)}
                            />
                        }
                    />
                </div>
                <UIField.StateValueField
                    name="Legal Team"
                    state={props.state.team}
                    editing
                />
                <UIField.CustomField
                    name="Legal Sub Team"
                    errorText={props.state.subTeam.errorText}
                    child={
                        <InputTokens
                            values={props.state.subTeam.value}
                            setValues={props.state.subTeam.setValue}
                        />
                    }
                />
                <UIField.StateValueField
                    name="Scope"
                    state={props.state.scope}
                    helpInfo={<Popover
                        dismissAriaLabel="Close"
                        header="Legal Contact's Scope"
                        triggerType="custom"
                        content={<Box color="text-body-secondary">
                            Briefly describe the scope of their role, including specialized subject matter expertise, custom coverage details, and geography covered.
                            <br /><br />
                            (e.g., <em>Import Controls and Customs - EMEA, AWS Snow products, etc.</em>)
                        </Box>}
                    >
                        <Link variant="info">Need Help?</Link>
                    </Popover>}
                    variant="TextArea"
                    editing={true}
                />
            </ColumnLayout>
        </>
    );
};