import * as React from "react";
import { StrongRichTextEdit as JoditBasedRichTextEdit } from "./JoditBased/StrongRichTextEdit";
import { StrongRichTextEdit as TinyMCEBasedRichTextEdit } from "./TinyMCEBased/StrongRichTextEdit";

export const SmartStrongRichTextEdit = (props: {
    content: string;
    onChange: (v: string) => void;
}) => {
    if (true) {
        return <TinyMCEBasedRichTextEdit content={props.content} onChange={props.onChange}/>;
    } else {
        return <JoditBasedRichTextEdit content={props.content} onChange={props.onChange}/>;
    }
};