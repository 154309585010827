import { Jodit } from "jodit";

export const RESTRICTED_HYPER_LINK_ONLY_CONFIG = {
    ...Jodit.defaultOptions,
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    buttons: "link", // only link button is provided in the editor
    style: {
        font: "18px 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif", // Lock font size
    },
    editorCssClass: "diff",
    toolbarAdaptive: false,
    enter: "br",
    defaultActionOnPaste: "insert_only_text", // default insert only text
    askBeforePasteFromWord: false, // do not ask when pasting
    askBeforePasteHTML: false // do not ask when pasting
};