import { SearchInterface } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";
import { RetrieveCommandOutput } from "@aws-sdk/client-kendra";

export class SearchAPI {
    constructor (private context: AppContextValue) {}

    async search(
        input: SearchInterface.SearchQueryInput
    ): Promise<APIResponse<SearchInterface.SearchQueryResponse>> {
        const response: APIResponse<SearchInterface.SearchQueryResponse> = await RestAPI.post(
            "custom-domain",
            "/search/query",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async feedback(
        input: SearchInterface.SubmitFeedbackInput
    ): Promise<APIResponse<any>> {
        const response: APIResponse<any> = await RestAPI.post(
            "custom-domain",
            "/search/feedback",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async querySuggestion(
        input: SearchInterface.QuerySuggestionInput
    ): Promise<APIResponse<SearchInterface.QuerySuggestionOutput>> {
        const response: APIResponse<SearchInterface.QuerySuggestionOutput> = await RestAPI.post(
            "custom-domain",
            "/search/query-suggestion",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async generateAnswer(
        input: SearchInterface.GenerateAnswerRequest
    ): Promise<APIResponse<SearchInterface.GenerateAnswerResponse>> {
        const response: APIResponse<SearchInterface.GenerateAnswerResponse> = await RestAPI.post(
            "custom-domain",
            "/search/generate-answer",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}