import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import { AppContext } from "../../../../setup/context";
import { Preference } from "../../../../setup/preference";
import { JoditBaseEditor } from "../../JoditBaseEditor";
import { RoleBasedPermissionedContentEditModule } from "../custom-modules/RoleBasedPermissionedContentModule";
import { TeamBasedPermissionedContentEditModule } from "../custom-modules/TeamBasedPermissionedContentModule";
import { ENHANCED_WRITE_CONFIG } from "./jodit-config/enhanced-indentation";

export const StrongRichTextEdit = (props: {
    content: string;
    onChange: (content: string) => void;
    showDiff?: boolean;
    custom?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const permissionContentActive = UIModel.State.use<boolean>({ initialValue: false });

    return (
        <React.Fragment>
            <RoleBasedPermissionedContentEditModule active={permissionContentActive}/>
            <JoditBaseEditor
                content={props.content}
                onChange={e => props.onChange(e)}
                config={{
                    ...ENHANCED_WRITE_CONFIG,
                    extraButtons: {
                        name: "Add Permission Content",
                        icon: "lock",
                        tooltip: "Add Permission Content",
                        exec: (editor) => permissionContentActive.setValue(true)
                    }
                }}
            />
        </React.Fragment>
    );
};
