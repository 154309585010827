import {
    EntityRef,
    ILegalContact,
    Identity,
    LegalContact
} from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import { Builder } from "builder-pattern";
import { EntityVersionRef } from "@amzn/altar-sds-low-level-client";

export namespace LegalContactModel {
    export class UpdateState {
        legalContactUser: UIModel.State<Identity>;
        businessLeader: UIModel.State<Identity[]>;
        team: UIModel.State<string>;
        subTeam: UIModel.State<string[]>;
        scope: UIModel.State<string>;
        containerRef: EntityRef;
        entityVersionRef?: UIModel.State<EntityVersionRef>;
        init: (entity?: LegalContact.Record) => void;
        reset: () => void;

        static toUpdateInput (state: UpdateState): ILegalContact.UpdateLegalContactInput {
            return ILegalContact.UpdateLegalContactInput.create({
                entityVersionRef: state.entityVersionRef.value,
                containerId: state.containerRef.id,
                legalContactUser: state.legalContactUser.value,
                legalTeam: state.team.value.trim(),
                legalSubTeam: state.subTeam.value,
                legalScope: state.scope.value.trim(),
                businessLeader: state.businessLeader.value
            });
        }

        static toCreateInput(state: UpdateState): ILegalContact.CreateLegalContactInput {
            return ILegalContact.CreateLegalContactInput.create({
                containerRef: state.containerRef,
                legalContactUser: state.legalContactUser.value,
                legalTeam: state.team.value.trim(),
                legalSubTeam: state.subTeam.value,
                legalScope: state.scope.value.trim(),
                businessLeader: state.businessLeader.value
            });
        }

        static use(props: {
            containerRef: EntityRef
        }): UpdateState {
            const legalContactUserField = UIModel.State.useRequired<Identity>({});
            const businessLeaderField = UIModel.State.useArrayWithConstraint<Identity>({
                initialValue: [],
                validation: (x) => {
                    if (x.length === 0) return "Value cannot be empty";
                    if (x.some(y => y.id === legalContactUserField.value?.id)) return "Legal contact cannot be their own busniess leader";
                }
            });
            const legalTeamField = UIModel.State.useNotNullString({});
            const legalSubTeamField = UIModel.State.useRequiredArray({
                initialValue: []
            });
            const legalScopeField = UIModel.State.useNotNullString({});
            const entityVersionRef = UIModel.State.use<EntityVersionRef>({});

            const init = (entity?: LegalContact.Record) => {
                if (entity) {
                    legalContactUserField.setValue(entity.legalContactUser);
                    legalTeamField.setValue(entity.legalTeam);
                    legalSubTeamField.setValue(entity.legalSubTeam);
                    legalScopeField.setValue(entity.legalScope);
                    businessLeaderField.setValue(entity.businessLeader);
                    entityVersionRef.setValue(entity.ref);
                } else {
                    legalContactUserField.setValue(null);
                    legalTeamField.setValue("");
                    legalSubTeamField.setValue([]);
                    legalScopeField.setValue("");
                    businessLeaderField.setValue([]);
                    entityVersionRef.setValue(undefined);
                }
            };

            const reset = () => {
                legalContactUserField.setValue(null);
                legalTeamField.setValue("");
                legalSubTeamField.setValue([]);
                legalScopeField.setValue("");
                businessLeaderField.setValue([]);
                entityVersionRef.setValue(undefined);
            };

            return Builder<UpdateState>()
                .legalContactUser(legalContactUserField)
                .team(legalTeamField)
                .subTeam(legalSubTeamField)
                .scope(legalScopeField)
                .businessLeader(businessLeaderField)
                .containerRef(props.containerRef)
                .entityVersionRef(entityVersionRef)
                .init(init)
                .reset(reset)
                .build();
        }
    }
}