import {
    AdminPreferences,
    AppError,
    EntityPermissionOperationNames,
    EntityType,
    IEntityPermission,
    KendraConst,
    SearchInterface
} from "@amzn/ask-legal-domain";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { LoadingSpinner } from "../../components/common/loading-spinner";
import { ErrorFlashbar } from "../../components/common/ErrorFlashbar";
import { UIModel } from "../../model/ui-model";
import {
    Box,
    Button,
    Container,
    ExpandableSection,
    Grid,
    Header,
    Icon,
    Input,
    Link,
    Popover,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import { PermissionDenied } from "../redirects/PermissionDenied";
import { StringUtils, isInRange } from "../../components/common/common-utils";
import "../../styles/component/layout/SearchResultPageLayout.scss";
import "../../styles/component/layout/PageBaseLayout.scss";

export const GenerativeSearchHome = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const isAdminAuthorizedRunner = useAPI(
        context.getEntityPermissionAPI().isAuthorized
    );

    React.useEffect(() => {
        isAdminAuthorizedRunner.submitRun(
            IEntityPermission.IsAuthorizedInput.create(
                props.instanceId,
                EntityType.Instance,
                EntityPermissionOperationNames.CAN_ADMIN
            )
        );
    }, [props.instanceId]);

    return <>
        {isAdminAuthorizedRunner.status === "Running" &&
            <Box textAlign="center" margin={{ top: "xl" }} className="awsui-util-status-inactive">
                <Spinner size="big" /> Checking permissions
            </Box>
        }
        {isAdminAuthorizedRunner.status === "Error" && isAdminAuthorizedRunner.data.err.code !== 403 && <ErrorFlashbar error={AppError.create("An unexpected error occured, please reload page", 500)} />}
        {isAdminAuthorizedRunner.status === "Error" && isAdminAuthorizedRunner.data.err.code === 403 && <PermissionDenied entityId={props.instanceId} accessType="InstanceAdminPortal" />}
        {isAdminAuthorizedRunner.status === "Succeeded" &&
            <GenerativeSearchComp instanceId={props.instanceId} />
        }
    </>;
};

export const GenerativeSearchComp = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const loadInstanceRunner = useAPI(
        context.getInstanceAPI().load
    );

    const generateAnswerRunner = useAPI(
        context.getSearchAPI().generateAnswer
    );

    // Main state to track user search query
    const queryInputState = UIModel.State.use<SearchInterface.SearchQueryInput>({});
    const [instancePreferences, setInstancePreferences] = React.useState<AdminPreferences.InstancePreferences>();
    const [queryText, setQueryText] = React.useState<string>("");

    const constructSearchQueryInput = (
        queryText: string,
        preferences?: AdminPreferences.InstancePreferences
    ): SearchInterface.SearchQueryInput => {
        let searchQuery = SearchInterface.SearchQueryInput.create({
            instanceId: props.instanceId,
            queryText: queryText
        }, {
            pageSize: 10,
            pageNumber: 1
        });
        if (preferences) {
            if (preferences.search.filterPrefs.showFileType.length > 0) {
                preferences.search.filterPrefs.showFileType.forEach(t => {
                    searchQuery = SearchInterface.SearchQueryInput.applyFilter(searchQuery, {
                        attribute: KendraConst.Attributes.Custom.ContentType,
                        operation: "EqualsTo",
                        valueMap: {
                            value: t,
                            valueType: KendraConst.IndexAttributeType.STRING
                        }
                    });
                });
            }
        }
        return searchQuery;
    };

    const generateAnswer = () => {
        generateAnswerRunner.submitRun(
            SearchInterface.GenerateAnswerRequest.create({
                searchQueryInput: queryInputState.value
            })
        );
    };

    React.useEffect(() => {
        loadInstanceRunner.submitRun(props.instanceId);
    }, [props.instanceId]);

    // Initialize search preferences
    React.useEffect(() => {
        if (loadInstanceRunner.status !== "Succeeded") return;
        if (!!loadInstanceRunner.data.output.preferences) {
            setInstancePreferences(loadInstanceRunner.data.output.preferences);
        } else {
            setInstancePreferences(AdminPreferences.DEFAULT_ADMIN_PREFERENCES);
        }
    }, [loadInstanceRunner.status]);

    // Re-construct a brand new query input object if queryText changed
    React.useEffect(() => {
        if (!instancePreferences) return;
        queryInputState.setValue(
            constructSearchQueryInput(queryText, instancePreferences)
        );
    }, [queryText, instancePreferences]);

    return (
        <React.Fragment>
            {loadInstanceRunner.status === "Error" &&
                (loadInstanceRunner.data.err.code === 403 ?
                    <PermissionDenied
                        entityId={props.instanceId}
                        accessType="HomePage"
                    /> :
                    <div className="top-margin-5p">
                        <ErrorFlashbar
                            error={loadInstanceRunner.data.err}
                            action={
                                <Button onClick={() => loadInstanceRunner.reload()}>
                                    Retry
                                </Button>
                            }
                        />
                    </div>
                )
            }
            {loadInstanceRunner.status === "Running" &&
                <div className="vertical-center top-margin-5p">
                    <Spinner />&nbsp;Loading search module...
                </div>
            }
            {loadInstanceRunner.status === "Succeeded" && queryInputState.value &&
                <div className="search-page-layout">
                    <br/>
                    <h2> Generative Search</h2>
                    <br/>
                    <Box textAlign="center" padding={"xxl"}>
                        <Grid
                            gridDefinition={[
                                {colspan: 5, offset: 3},
                                {colspan: 3}
                            ]}
                        >
                            <Input
                                value={queryText}
                                onChange={({ detail }) => setQueryText(detail.value)}
                                inputMode="text"
                                placeholder="Enter a query..."
                                type="search"
                                autoFocus
                                spellcheck
                                onKeyDown={({ detail }) => {
                                    if (detail.keyCode === 13) {
                                        generateAnswer();
                                    }
                                }}
                            />
                            <SpaceBetween size="m" direction="horizontal">
                                <Button
                                    variant="primary"
                                    onClick={generateAnswer}
                                    disabled={
                                        !isInRange(queryInputState.value.queryText.length, 3, 1000)
                                    }
                                >
                                    Search
                                </Button>
                            </SpaceBetween>
                        </Grid>
                    </Box>
                    <hr />
                    <div className="search-page-layout-result">
                        {generateAnswerRunner.status === "Running" &&
                            <div className="vertical-center top-margin-5p">
                                <LoadingSpinner msg={`Generating a response...`}/>
                            </div>
                        }
                        {generateAnswerRunner.status === "Error" && (
                            generateAnswerRunner.data.err.code === 403 ?
                                <PermissionDenied entityId={props.instanceId} accessType="HomePage"/> :
                                <ErrorFlashbar error={generateAnswerRunner.data.err}/>
                        )}
                        {generateAnswerRunner.status === "Succeeded" && !!generateAnswerRunner.data.output && (
                            <React.Fragment>
                                <GenerativeAnswerComp
                                    answer={generateAnswerRunner.data.output.answer}
                                    references={generateAnswerRunner.data.output.references}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

const GenerativeAnswerComp = (props: {
    answer: string;
    references?: {
        title: string,
        url: string,
        content?: string
    }[];
}) => {
    return (
        <>
            <br />
            <div className="search-page-generated awsui-polaris-dark-mode">
                <span style={{color: "#ec7211"}}><em>
                    <small>
                        Generative AI search is experimental &nbsp;&nbsp;&nbsp;&nbsp;
                        <Popover
                            header="About this result"
                            size="large"
                            content={
                                <SpaceBetween size="s">
                                    <Container
                                        header={<Header>Source</Header>}
                                    >
                                        This answer is generated by an AI system using only the information available in Pathfinder. The AI uses its reasoning abilities to fully address the question.
                                        <br/>
                                        To ensure your search finds the intended content page for your query, check the references listed below.
                                        <br/>
                                        <em>The answer may sometimes speak of a context which is the set of references as listed below. Use your judgment to identify any inaccuracies in the answer based on the listed references.</em>
                                    </Container>
                                    <Container
                                        header={<Header>How to improve your search?</Header>}
                                    >
                                        To get better search results:
                                        <ul>
                                            <li>Include lots of details in your search queries to make them less ambiguous.</li>
                                            <li>Be clear about what you are looking for.</li>
                                            <li>If the results don't satisfy you, rephrase your search question like you would if asking another person.</li>
                                            <li>Also, try asking the same questions to the existing search engine. See how the responses from the search engine compare to the responses from this search.</li>
                                        </ul>
                                    </Container>
                                    <Container
                                        header={<Header>Leave Feedback</Header>}
                                    >
                                        We are eager to hear your feedback. Please let us know about any surprising moments, whether good or bad.
                                        Your feedback in all forms will help us shape the product to better enable you to easily find what you want!
                                    </Container>
                                </SpaceBetween>
                            }
                        >
                            <Link variant="info"><Icon name="status-info" /></Link>
                        </Popover>
                    </small>
                </em></span>
                <br />
                <br />
                <pre style={{fontFamily: "Amazon Ember", color: "white", whiteSpace: "pre-line", wordWrap: "normal"}}>{props.answer}</pre>
                <br />
                <br />
                {props.references && props.references.length > 0 &&
                    <SpaceBetween size="m">
                        <hr style={{margin: "1em", borderColor: "gray"}}/>
                        <h6 style={{color: "#ec7211"}}>References:</h6>
                        <SpaceBetween size="m">
                            {props.references.map((x, i) => <ExpandableSection
                                variant="navigation"
                                headerText={<Link href={x.url} external>{`${i + 1}. ${StringUtils.stripHtml(x.title)}`}</Link>}
                            >{x.content}</ExpandableSection>)}
                        </SpaceBetween>
                    </SpaceBetween>
                }
            </div>
            <br />
        </>
    );
};