import * as React from "react";
import { LegalContactTable } from "../LegalContactTable";
import { LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";
import { Box } from "@amzn/awsui-components-react";

export const ViewRecommendedLegalContacts = (props: {
    items: LegalContact.Record[];
    content: WhosMyLawyerContent;
    costCenterId: string;
    reloadAction: () => void;
    loading?: boolean;
}) => {
    return (
        <LegalContactTable
            records={props.items
                .filter(l => l.businessLeaderCostCenterIds
                    ?.find(id => id.costCenterId === props.costCenterId)
                )
            }
            reloadData={props.reloadAction}
            loading={props.loading}
            defaultSortState={WhosMyLawyerFactory.getSortingStateFromContent(props.content)}
            defaultPreference={WhosMyLawyerFactory.toPreferences(props.content)}
            headerContent="Recommended Contacts"
            headerDescription={<Box color="text-body-secondary">
                Legal Contacts are recommended when they are associated with your Business Leader(s)
            </Box>}
        />
    );
};