import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import {
    Checkbox,
    CollectionPreferences,
    ColumnLayout,
    Header,
    Select
} from "@amzn/awsui-components-react";
import { UIField } from "../../common/UIField";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";
import { WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { LegalContactsPolarisFactory } from "../../../factory/polaris/legal-contacts-polaris-factory";

export const WhosMyLawyerContentEdit = (props: {
    state: UIModel.State<WhosMyLawyerContent>;
}) => {
    return <>
        <hr />
        <Header variant="h3">Default Table View Configuration</Header>
        <ColumnLayout columns={2}>
            <UIField.CustomField
                name="Sorting field"
                child={<Select
                    selectedOption={WhosMyLawyerFactory.toOptionDefinition(
                        props.state.value?.defaultConfiguration.sorting?.field
                    )}
                    onChange={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.create({
                            ...props.state.value.defaultConfiguration,
                            sorting: {
                                field: detail.selectedOption.value,
                                order: props.state.value.defaultConfiguration.sorting.order
                            }
                        })
                    )}
                    options={WhosMyLawyerFactory.getSortSelectOptions()}
                />}
            />
            <UIField.CustomField
                name="Sorting order"
                child={<Checkbox
                    checked={props.state.value?.defaultConfiguration.sorting?.order === "ASC"}
                    onChange={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.create({
                            ...props.state.value.defaultConfiguration,
                            sorting: {
                                field: props.state.value.defaultConfiguration.sorting.field,
                                order: detail.checked ? "ASC" : "DESC"
                            }
                        })
                    )}
                >
                    isAscending
                </Checkbox>}
            />
            <UIField.LabelField
                label="Manage view preferences"
                children={<CollectionPreferences
                    preferences={WhosMyLawyerFactory.toPreferences(props.state.value)}
                    onConfirm={({ detail }) => props.state.setValue(
                        WhosMyLawyerFactory.toContentFromPreferences(
                            detail,
                            props.state.value
                        )
                    )}
                    pageSizePreference={{
                        options: LegalContactsPolarisFactory.DEFAULT_PAGE_SIZE_OPTIONS,
                        title: "Number of contacts per page"
                    }}
                    stripedRowsPreference={{
                        label: "Striped rows",
                        description: "Select to add alternating shaded rows"
                    }}
                    contentDisplayPreference={{
                        options: LegalContactsPolarisFactory.Table.getColumnDisplayOptions(),
                        title: "Default column view preferences",
                        description: "Customize the columns visibility and order"
                    }}
                    cancelLabel="Cancel"
                    confirmLabel="Save"
                    title="Set default table preferences"
                />}
            />
        </ColumnLayout>
    </>;
};