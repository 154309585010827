import * as React from "react";
import { JoditBaseEditor } from "../../JoditBaseEditor";
import { RESTRICTED_HYPER_LINK_ONLY_CONFIG } from "./jodit-config/hyperlink-only-config";

export const RestrictedHyperLinkOnlyRichTextEdit = (props: {
    content: string;
    onChange: (content: string) => void;
}) => {
    return (
        <React.Fragment>
            <JoditBaseEditor
                content={props.content}
                onChange={e => props.onChange(e)}
                config={RESTRICTED_HYPER_LINK_ONLY_CONFIG}
            />
        </React.Fragment>
    );
};
