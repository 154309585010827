import * as React from "react";
import { KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import { SpaceBetween, Box, Badge, Link, StatusIndicator, TextContent, Flashbar } from "@amzn/awsui-components-react";
import { DateTimeFormatter } from "../../common/DateTimeFormatter";
import { useAPI } from "../../../hooks/api-hook";
import { AppContext } from "../../../setup/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { QueryResultItem }  from "@aws-sdk/client-kendra";

export const SuggestedAnswers = (props: {
    instanceId: string,
    result: QueryResultItem[],
    queryId: string
}) => {

    const context = React.useContext(AppContext);
    const [feedbackSubmitted, setFeedbackSubmitted] = React.useState<string[]>([]);
    const [feedbackStatusUpdates, setFeedbackStatusUpdates] = React.useState<Array<any>>([]);

    const submitFeedbackRunner = useAPI(
        context.getSearchAPI().feedback
    );

    const submitFeedback = (queryId: string, resultId: string, relevance: KendraConst.FeedbackRelevanceValue) => {
        submitFeedbackRunner.submitRun(
            SearchInterface.SubmitFeedbackInput.create({
                instanceId: props.instanceId,
                queryId: queryId,
                relevanceFeedbackItems: [{
                    RelevanceValue: relevance,
                    ResultId: resultId
                }]
            })
        );
    };

    React.useEffect(() => {
        if (submitFeedbackRunner.status === "Succeeded") {
            const tempSet = new Set(feedbackSubmitted);
            submitFeedbackRunner.data.input.relevanceFeedbackItems.forEach(i => tempSet.add(i.ResultId));
            setFeedbackSubmitted(Array.from(tempSet));

            setFeedbackStatusUpdates([{
                type: "info",
                content: `Thank you for your feedback.`,
                dismissible: true,
                onDismiss: () => setFeedbackStatusUpdates([])
            }]);
        }
    }, [submitFeedbackRunner.status]);

    return (
        <div>
            <SpaceBetween direction="vertical" size="xs">
                <Flashbar items={feedbackStatusUpdates}/>
                {props.result.length === 1 && <h5>Suggested Answer</h5>}
                {props.result.length > 1 && <h5>Suggested Answers</h5>}
                {props.result.map(r => (
                    <div className="search-page-suggested">
                        <p>
                            {r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.LegalOnly)?.Value.StringValue === "true" &&
                                <Box variant="small" color="text-body-secondary" margin={{right: "m"}} padding="n">
                                    <Badge color="green">Legal Only</Badge>
                                </Box>}
                                <span style={{ color: "grey" }}>
                                {DateTimeFormatter.toSimpleDate(r.DocumentAttributes.find(
                                        a => a.Key === KendraConst.Attributes.Default.LastUpdatedDate
                                        )?.Value.StringValue)}
                                </span>&ensp;{r.DocumentExcerpt.Text}
                            <Link href={r.DocumentURI} target="_blank"> [Read More...]</Link>
                        </p>
                        <SpaceBetween direction="horizontal" size="m">
                            <Link external href={r.DocumentURI}>
                                {r.DocumentTitle.Text}
                            </Link>
                            { feedbackSubmitted.some(id => r.Id === id) ? (
                                <></>
                            ) :
                            submitFeedbackRunner.status === "Running" && submitFeedbackRunner.data.input.relevanceFeedbackItems.some(res => r.Id === res.ResultId) ? <StatusIndicator type="loading">Submitting response</StatusIndicator> :
                                <React.Fragment>
                                    <TextContent>
                                        <small><em>Is this answer helpful?</em></small>
                                    </TextContent>
                                    <span style={{ color: "darkorange", cursor: "pointer" }} onClick={() => submitFeedback(props.queryId, r.Id, "RELEVANT")}>
                                        <FontAwesomeIcon  icon={faThumbsUp} size={"lg"}/>
                                    </span>
                                    <span style={{ color: "darkorange", cursor: "pointer" }} onClick={() => submitFeedback(props.queryId, r.Id, "NOT_RELEVANT")}>
                                        <FontAwesomeIcon  icon={faThumbsDown} size={"lg"}/>
                                    </span>
                                </React.Fragment>
                            }
                        </SpaceBetween>
                    </div>
                ))}
            </SpaceBetween>
        </div>
    );
};