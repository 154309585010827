import * as React from "react";
import { Grid, SpaceBetween } from "@amzn/awsui-components-react";
import { SuggestedAnswers } from "./SuggestedAnswers";
import { FAQAnswers } from "./FAQAnswers";
import { LinkAnswers } from "./LinkAnswers";
import { QueryResultItem }  from "@aws-sdk/client-kendra";

export const SmartAnswersView = (props: {
    suggestedResults: QueryResultItem[],
    faqResults: QueryResultItem[],
    linkResults: QueryResultItem[],
    instanceId: string,
    searchQueryId: string
}) => {
    const [viewType, setViewType] = React.useState("");
    const [gridDefinition, setGridDefinition] = React.useState([]);

    React.useEffect(() => {
        if (props.suggestedResults.length > 0 && props.faqResults.length > 0 && props.linkResults.length > 0) {
            setViewType("Trio");
            setGridDefinition([
                { colspan: { default: 3, xxs: 9} },
                { colspan: { default: 9, xxs: 3} }
            ]);
        } else if (
            (props.suggestedResults.length > 0 && props.faqResults.length <= 0 && props.linkResults.length > 0) ||
            (props.suggestedResults.length <= 0 && props.faqResults.length > 0 && props.linkResults.length > 0)
        ) {
            setViewType("DuoH");
            setGridDefinition([
                { colspan: { default: 3, xxs: 9} },
                { colspan: { default: 9, xxs: 3} }
            ]);
        } else if (props.suggestedResults.length > 0 && props.faqResults.length > 0 && props.linkResults.length <= 0) {
            setViewType("DuoV");
        } else {
            setViewType("Mono");
            setGridDefinition([]);
        }

    }, [props.suggestedResults, props.faqResults, props.linkResults]);

    return (
        <React.Fragment>
            {viewType === "Trio" &&
                <Grid
                    gridDefinition={gridDefinition}
                >
                    <SpaceBetween size="m" direction="vertical">
                        <SuggestedAnswers result={props.suggestedResults} instanceId={props.instanceId} queryId={props.searchQueryId} />
                        <FAQAnswers result={props.faqResults} />
                    </SpaceBetween>
                    <LinkAnswers results={props.linkResults} />
                </Grid>
            }
            {viewType === "DuoH" &&
                <Grid
                gridDefinition={gridDefinition}
            >
                <SpaceBetween size="m" direction="vertical">
                    {props.suggestedResults.length > 0 && <SuggestedAnswers result={props.suggestedResults} instanceId={props.instanceId} queryId={props.searchQueryId} />}
                    {props.faqResults.length > 0 && <FAQAnswers result={props.faqResults} />}
                </SpaceBetween>
                <LinkAnswers results={props.linkResults} />
            </Grid>
            }
            {viewType === "DuoV" &&
                <SpaceBetween size="m" direction="vertical">
                    <SuggestedAnswers result={props.suggestedResults} instanceId={props.instanceId} queryId={props.searchQueryId} />
                    <FAQAnswers result={props.faqResults} />
                </SpaceBetween>
            }
            {viewType === "Mono" &&
                <SpaceBetween size="m" direction="vertical">
                    {props.suggestedResults.length > 0 && <SuggestedAnswers result={props.suggestedResults} instanceId={props.instanceId} queryId={props.searchQueryId} />}
                    {props.faqResults.length > 0 && <FAQAnswers result={props.faqResults} />}
                    {props.linkResults.length > 0 && <LinkAnswers results={props.linkResults} />}
                </SpaceBetween>
            }
        </React.Fragment>
    );
};